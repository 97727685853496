<template>
  <v-card class="d-flex border-radius my-8">
    <v-avatar
      color="#008194"
      class="ma-3 border-radius"
      size=62
    >
      <v-icon color="#f2f2f2">{{icon}}</v-icon>
    </v-avatar>
    <div>
      <v-card-title :class="{'error--text': emphasis}">{{title}}</v-card-title>
      <v-card-subtitle>{{text}}</v-card-subtitle>
    </div>
    <v-spacer />
    <v-icon
      class="mr-4"
      large
    >
      {{$icon('i.ChevronRight')}}
    </v-icon>
  </v-card>
</template>

<script>
export default {
  props: {
    emphasis: Boolean,
    icon: String,
    text: String,
    title: String
  }
}
</script>
